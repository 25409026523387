import React, { useState, useCallback, memo, useEffect } from "react";
import { Modal, Button, Tab, Tabs, Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "Helper/Converters";
import { format } from "date-fns";
import PayrollLogTableTab from "./tabs/PayrollLogTableTab";
import PayrollTicketSummaryTab from "./tabs/PayrollTicketSummaryTab";
import PayrollReimbursementTab from "./tabs/PayrollReimbursementTab";

const PayrollLogModal = memo(({ show, handleClose, logDate, setLogDate, selectedUserId }) => {
  console.log("DUCK", "PayrollLogModal:logDate", logDate);
  const [key, setKey] = useState("logTable");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  //dummy
  const dropdownOptions = [
    { label: "Work", value: "Work" },
    { label: "Lunch", value: "Lunch" },
  ];

  // Restrict future dates
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const handleDateChange = (date) => {
    setLogDate(date);
    setDatePickerVisible(false); // Hide DatePicker after selection
  };

  useEffect(() => {
    // Ensure the DatePicker is hidden when the modal is not shown
    if (!show) {
      setDatePickerVisible(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setDatePickerVisible(false); // Ensure DatePicker is hidden when modal is closed
      }}
      size="lg"
      dialogClassName="custom-modal-width"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Selected Date:
          <span
            onClick={() => setDatePickerVisible(!isDatePickerVisible)}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            ({formatDate(logDate)})
          </span>
          {isDatePickerVisible && (
            <div style={{ position: "absolute", zIndex: 1050 }}>
              <DatePicker
                selected={new Date(logDate)}
                onSelect={handleDateChange}
                maxDate={new Date()}
                inline
              />
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
          <Tab eventKey="logTable" title="Hours Log">
            <PayrollLogTableTab
              dropdownOptions={dropdownOptions}
              logDate={logDate}
              selectedUserId={selectedUserId}
              key={logDate}
            />
          </Tab>
          <Tab eventKey="ticketSummary" title="Tickets Summary">
            <PayrollTicketSummaryTab
              logDate={logDate}
              selectedUserId={selectedUserId}
              key={logDate}
            />
          </Tab>
          <Tab eventKey="reimbursement" title="Reimbursements">
            <PayrollReimbursementTab
              logDate={logDate}
              selectedUserId={selectedUserId}
              key={logDate}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default PayrollLogModal;
