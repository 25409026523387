import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import classes from "./index.module.scss";

import TicketAPIs from "../../APIs/ticket";
import { formatDate, formatTime } from "Helper/Converters";

const LogModal = ({ id, ...props }) => {
  const [ticketLogs, setTicketLogs] = useState([]);

  const getTicketLogs = async (id) => {
    if (id) {
      const tickets = await TicketAPIs.ticketLogs({
        ticketId: id,
      });

      if (tickets) {
        console.log("DUCK", "getTicketLogs:id", id);
        console.log("DUCK", "getTicketLogs:ticket", tickets.data.data);
        setTicketLogs(tickets.data.data);
      }
    }
  };

  useEffect(() => {
    getTicketLogs(id);
  }, [id]);

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="text-dark mb-0">Ticket Log</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className={classes.LogList}>
          {ticketLogs.length > 0 ? (
            ticketLogs.map((item, ind) => {
              return (
                <li key={ind}>
                  <p>
                    <strong>Action: </strong>
                    {item?.action}
                  </p>
                  <p>
                    <strong>Remarks:</strong> {item?.remarks}
                  </p>
                  <p>
                    <strong>Date:</strong> {formatDate(item?.createdAt)}
                  </p>
                  <p>
                    <strong>Time:</strong> {formatTime(item?.createdAt)}
                  </p>
                </li>
              );
            })
          ) : (
            <li>
              <p>No Logs available!</p>
            </li>
          )}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>CLose</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogModal;
