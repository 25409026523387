import React, { memo, useEffect, useMemo, useState } from "react";
import { Button, Table, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Formik, FieldArray } from "formik";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import {
  BiEdit,
  BiLike,
  BiSave,
  BiSolidHandDown,
  BiSolidHandRight,
  BiSolidHandUp,
  BiTrash,
} from "react-icons/bi";

import HourLogAPIs from "../../../../APIs/hour-log";
import { toast } from "react-toastify";
import { formatDate, formatTime } from "Helper/Converters";
import { format } from "date-fns";
import Checkbox from '@mui/material/Checkbox';

let endTime = new Date();
endTime.setHours(23, 59, 0, 0)

const PayrollLogTableTab = memo(({ logDate, selectedUserId }) => {
  const dropdownOptions = [
    { label: "Work", value: "Work" },
    { label: "Lunch", value: "Lunch" },
  ];

  const dropdownWorkCodeOptions = [
    { label: "WorkHours", value: "WorkHours" },
    { label: "StatHoliday", value: "StatHoliday" },
    { label: "Vacation", value: "Vacation" },
  ];

  const [hourLogs, setHourLogs] = useState([]);

  // State to track editing status of each log
  const [editingLogs, setEditingLogs] = useState({});
  const [editState, setEditState] = useState(null);
  const [approveLoader, setApproveLoader] = useState(false)
  const [disapproveLoader, setDisapproveLoader] = useState(false)

  // Function to toggle edit mode
  const toggleEdit = (logId) => {
    setEditingLogs((prev) => ({
      ...prev,
      [logId]: !prev[logId],
    }));
  };

  const checkAllSelected = useMemo(() => {
    if (hourLogs.length > 0)
      return hourLogs.every(item => item.selected)
    return false
  }, [hourLogs])

  const toggleAllSelect = () => {
    if (checkAllSelected)
      setHourLogs(prev => prev.map(item => {
        item.selected = false
        return { ...item }
      }))
    else
      setHourLogs(prev => prev.map(item => {
        item.selected = true
        return { ...item }
      }))
  }

  const fetchQueryHourLogs = async (data) => {
    try {
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      const response = await HourLogAPIs.getQueryHourLog(data);
      console.log("DUCK", "hourLogs", response);
      if (response && response.data && response.data.data.length > 0) {
        setHourLogs(response.data.data[0].hourLogDetails);
      } else {
        setHourLogs([]);
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
      setHourLogs([]);
    }
  };

  const updateApproval = async (isApproved) => {
    try {
      if (hourLogs.filter(item => item.selected).length > 0) {
        isApproved ? setApproveLoader(true) : setDisapproveLoader(true)
        const hourLogDetailIds = []
        hourLogs.forEach(item => {
          if (item.selected)
            hourLogDetailIds.push(item.id)
        })
        console.log('hourLogDetailIds', hourLogDetailIds)
        const data = {
          hourLogDetailIds,
          isApproved
        }
        const response = await HourLogAPIs.updateApproval(data);
        if (response.status == 200) {
          toast.success(response.data.message)
          setHourLogs(prev => prev.map(item => {
            item.selected = false
            return { ...item }
          }))
          fetchQueryHourLogs({
            date: format(new Date(logDate), "yyyy-MM-dd"),
            logType: "HOUR_LOG",
            userId: selectedUserId
          });
        } else {
          toast.error(response.data?.message || 'Something went wrong!')
        }
        console.log("DUCK", "updateApproval", response);
      } else {
        alert('Please select atleast one hour log!')
      }
    } catch (error) {
      console.error("Error updating approval:", error);
    } finally {
      isApproved ? setApproveLoader(false) : setDisapproveLoader(false)
    }
  };

  useEffect(() => {
    fetchQueryHourLogs({
      date: format(new Date(logDate), "yyyy-MM-dd"),
      logType: "HOUR_LOG",
      userId: selectedUserId
    });
  }, []);

  return (
    <>
      <Row>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>
                <input
                  class="form-check-input fs-4 m-0"
                  type="checkbox"
                  checked={checkAllSelected}
                  onChange={toggleAllSelect}
                />
              </th>
              <th>Approved</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Ticket Number</th>
              <th>Note</th>
              <th>Work Log</th>
              <th>WorkStartDate Time</th>
              <th>WorkEndDate Time</th>
              <th>WorkCode</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {hourLogs.map((log, index) => {
              return (
                <tr key={log.id || index}>
                  <td>
                    <input
                      class="form-check-input fs-4 m-0"
                      type="checkbox"
                      checked={log.selected}
                      onChange={() => {
                        hourLogs[index].selected = !hourLogs[index].selected
                        setHourLogs([...hourLogs])
                      }}
                    />
                  </td>
                  <td>
                    {log.isApproved ? (
                      <BiLike color="green" size={30} />
                    ) : (
                      <BiLike color="red" size={30} />
                    )}
                  </td>
                  <td>{`${formatTime(log.startDate)}`}</td>
                  <td>{`${formatTime(log.endDate)}`}</td>
                  <td>{log.ticketNo}</td>
                  <td>{log.note}</td>
                  <td>{log.workLog}</td>
                  <td>{`${formatDate(log.startDate)} ${formatTime(log.startDate)}`}</td>
                  <td>{`${formatDate(log.endDate)} ${formatTime(log.endDate)}`}</td>
                  <td>{log.workCode}</td>
                  <td>{log.comments}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-flex my-3">
          <Button className="ms-5" style={{ backgroundColor: 'rgb(13, 195, 211)' }} onClick={() => updateApproval(true)}>{approveLoader ? 'Loading...' : 'Approve'}</Button>
          <Button className="ms-3" style={{ backgroundColor: '#da9807' }} onClick={() => updateApproval(false)}>{disapproveLoader ? 'Loading...' : 'Dis-Approve'}</Button>
        </div>
      </Row>
    </>
  );
});

export default PayrollLogTableTab;
