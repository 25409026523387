import React from "react";
import classes from "./index.module.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";

const Heading = ({ text, btn, refresh }) => {
  const navigate = useNavigate();
  return (
    <div className={`${classes.heading} flex-box mb-3`}>
      <h2>{text}</h2>
      {btn && (
        <Button
          onClick={() => {
            navigate("/dashboard/alert-messages");
          }}
          className={classes.iconBtn}
        >
          <span>000</span>Messages
        </Button>
      )}
      {refresh && (
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className={`exclude-print ${classes.iconBtn}`}
        >
          <FiRefreshCcw /> Refresh
        </Button>
      )}
    </div>
  );
};

export default Heading;
