import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ElementControl from "Components/ElementControl";
import routes from "./routes";
import { useSelector } from "react-redux";

const ClientRoutes = () => {
    const { accessToken, department } = useSelector((state) => state.auth);
    console.log('dapartement',department)
    const getElementControl = (path, authenticated, component, layout) => {

        if (accessToken && path === '/') {
            if (department?.name == 'Tester' || department?.name == 'admin' || department?.name == 'locator')
                return <Navigate to="/admin-dashboard" replace />
            return <Navigate to="/dashboard/query-search" replace />
        }

        if (!authenticated) {
            return <ElementControl Component={component} Layout={layout} />;
        }

        if (authenticated) {
            if (accessToken) {
                if (path === '/admin-dashboard' && department?.name != 'Tester' && department?.name != 'admin' && department?.name != 'locator') {
                    return <Navigate to="/dashboard/query-search" replace />
                }
                return <ElementControl Component={component} Layout={layout} />;
            } else {
                return <Navigate to="/" replace />;
            }
        }
    };


    return (
        <BrowserRouter>
            <Routes>
                {routes.map((route, index) => {
                    const { path, subRoutes, component, layout, authenticated } = route;
                    if (subRoutes && subRoutes.length > 0) {
                        return (
                            <Route key={`route_${index}`}>
                                {subRoutes.map((subRoute, subIndex) => {
                                    return (
                                        <Route
                                            key={`subroute_${subIndex}`}
                                            exact
                                            path={`${route.path}${subRoute.path}`}
                                            element={getElementControl(path, authenticated, subRoute.component, layout)}
                                        />
                                    );
                                })}
                            </Route>
                        );
                    }
                    return (
                        <Route
                            key={`route_${index}`}
                            exact
                            path={path}
                            element={getElementControl(path, authenticated, component, layout)}
                        />
                    );
                })}
            </Routes>
        </BrowserRouter>
    );
};

export default ClientRoutes;