import React, { useEffect, useState } from "react";
import TicketAPIs from "../../APIs/ticket";
import classes from "./index.module.scss";
import SearchModal from "Components/SearchModal";
import { Link,useLocation } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const SearchResults = ({ results, hideResults }) => {
  const location = useLocation();

  return (
    <ul className={classes.result}>
      {results.map((result) => (
        <li key={result.id}>
          {location.pathname.includes('/dashboard/ticket-detail') ? (
            <a href={`/dashboard/ticket-detail/${result.ticketNo}`} onClick={hideResults}>
              {result.ticketNo}
            </a>
          ) : (
            <Link to={`/dashboard/ticket-detail/${result.ticketNo}`} onClick={hideResults}>
              {result.ticketNo}
            </Link>
          )}
        </li>
      ))}
    </ul>
  );
};
const Search = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchResults = async () => {
      if (query.length >= 3) {
        setLoading(true);
        try {
          const searchParams = {
            ticketNo: query,
          };
          if (user?.role !== "admin") {
            searchParams.assignTo = user.id;
          }
          const response = await TicketAPIs.searchTicket(searchParams);
          const data = await response.data.data;
          setResults(data);
        } catch (error) {
          console.error("Error fetching results:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setResults([]);
      }
    };

    fetchResults();
  }, [query]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const hideResults = () => {
    setQuery("");
  };
  return (
    <div className={classes.search}>
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        className="form-control"
        placeholder="Type at least 3 characters..."
      />
      <button className={classes.searchBtn}>
        <i className="fas fa-search"></i>
      </button>

      {loading && (
        <Oval
          visible={true}
          height="20"
          width="20"
          color="#0073ba"
          wrapperClass={classes.loader}
          secondaryColor="#0073ba"
        />
      )}

      {results.length > 0 && (
        <SearchResults results={results} hideResults={hideResults} />
      )}
    </div>
  );
};

// const [modalShow, setModalShow] = useState(false);

// return (
//   <>
//     <div className={classes.search}>
//       <input
//         className="form-control"
//         type="text"
//         placeholder="Search Ticket"
//       />
//       <button
//         className={classes.searchBtn}
//         onClick={() => setModalShow(true)}
//       >
//         <i className="fas fa-search"></i>
//       </button>
//     </div>
//     {/ <SearchModal show={modalShow} onHide={() => setModalShow(false)} /> /}
//   </>
// );

export default Search;