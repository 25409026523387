import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { TbClipboardCheck } from "react-icons/tb";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable";
import { useSelector } from "react-redux";
import AuthAPIs from "../../APIs/auth";
import Loader from "Components/Loader";

const TicketByUser = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [tableData, setTableData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const { userId } = useSelector((state) => state.auth);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const options = [
    { value: "0", label: "Open this select menu" },
    { value: "1", label: "One" },
    { value: "2", label: "Two" },
    { value: "3", label: "Three" },
  ];

  const dummyData = [
    {
      id: "1",
      username: "Aaliyah Baldeo",
      assigned: "0",
      pre: "0",
      due: "0",
    },
    {
      id: "2",
      username: "Carles Clarke",
      assigned: "0",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
    {
      id: "3",
      username: "Darshdeep Singh",
      assigned: "17",
      pre: "0",
      due: "0",
    },
  ];

  const columns = [
    { id: "username", label: "User Name" },
    { id: "assigned", label: "Assigned To Ticket " },
    { id: "pre", label: "Pre Completed By" },
    { id: "due", label: "Overdue" },
  ];

  const getUsers = async () => {
    setIsLoading(true);
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    const tableArr = [];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) {
          customArr.push(data);
        }
        console.log(item);
        const tableData = {
          id: item?.id,
          firstName: item?.firstName,
          assigned: item?.assigned?.length,
          pre: item?.preCompleted?.length,
          due: "0",
        };
        tableArr.push(tableData);
      });
      setUsers(customArr);
      setTableData(tableArr);
    }
    setIsLoading(false);
  };

  const getSingleUser = async (id) => {
    setIsLoading(true);
    const tableArr = [];

    const res = await AuthAPIs.getOneUser(id);
    if (res) {
      console.log("SIngle user === ", res.data);
      const tableData = {
        id: res.data?.data?.id,
        firstName: res.data?.data?.firstName,
        assigned: res.data?.data?.assigned?.length,
        pre: res.data?.data?.preCompleted?.length,
        due: "0",
      };
      tableArr.push(tableData);
    }
    setTableData(tableArr);
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <section>
        <Heading text={"Outstanding Tickets By User"} />

        <Card>
          <Form className="dashboard-form mb-4">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label>Pre Completed By</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    defaultValue={users[0]}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption.value);
                      getSingleUser(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          {/* <DataTable columns={columns} data={tableData} style  setSelectedIds={setSelectedIds} /> */}
        </Card>
      </section>
    </>
  );
};

export default TicketByUser;
